<template lang="">
  <div class="title">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="投影管理" name="first">
        <div class="">
          <el-button @click="btnClick('preview')">预览</el-button>
          <!-- <el-button @click="btnClick('listval')">新增相机</el-button> -->
          <el-button @click="btnClick('addpaths')">小车航行</el-button>
          <el-button @click="btnClick('updateApi')" class="btn"
            >刷新api</el-button
          >
          <el-button @click="btnClick('cameraList')" class="btn"
            >投影相机</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="坐标管理" name="second">
        <el-button @click="btnClick('transformation')">坐标转换</el-button>
        <el-button @click="btnClick('query')">坐标查询</el-button>
        <!-- <el-button @click="btnClick('dot')">点位选择</el-button> -->
      </el-tab-pane>
      <!-- <el-tab-pane label="视野管理" name="third">角色管理</el-tab-pane> -->
      <el-tab-pane label="区域管理" name="fourth">
        <el-button @click="btnClick('region')">区域列表</el-button>
        <el-button @click="btnClick('regionConfig')">区域配置</el-button>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      activeName: "first",
    };
  },
  mounted() {
    const { active } = this.$route.query;
    if (active) {
      this.activeName = active;
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    btnClick(type) {
      console.log(type);
      this.$emit("btnClick", type);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 200;
  background: rgb(255, 255, 255);
  padding: 0 20px;
  box-sizing: border-box;
  .btn {
    margin-left: 20px;
  }
}
.el-tabs__item {
  font-size: 20px;
  font-weight: 700;
}
.el-tabs__item.is-active {
  color: #409eff;
}
.el-tabs__active-bar {
  background-color: #409eff;
}
</style>
