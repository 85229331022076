export const imageType = [
  {
    value: "jpg",
    label: "jpg",
  },
  {
    value: "png",
    label: "png",
  },
];
