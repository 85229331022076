<template lang="">
  <div class="spantext">
    <span>
      heading: {{ cameraParameter.heading }}; pitch:
      {{ cameraParameter.pitch }}; roll: {{ cameraParameter.roll }}; lng:
      {{ cameraParameter.lng }}; lat: {{ cameraParameter.lat }}; height:
      {{ cameraParameter.height }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    cameraParameter: {
      type: Object,
      default: {
        heading: 0,
        pitch: 0,
        roll: 0,
        lng: 0,
        lat: 0,
        height: 0,
      },
    },
  },
  data() {
    return {
      // cameraParameter: {
      //   heading: 0,
      //   pitch: 0,
      //   roll: 0,
      //   lng: 0,
      //   lat: 0,
      //   height: 0,
      // },
      longlat: "0,0,0",
      Cartesian3: "0,0,0",
    };
  },
  mounted() {
    // window.viewer.camera.changed.addEventListener((percentage) => {
    //   console.log(percentage, "视野相机改变");
    //   this.cameraParameter = getCameraParameter(Cesium, window.viewer);
    //   // if (newCesiumVideo3d) {
    //   //   this.cameraParameter = getEntitySceneParameter(
    //   //     Cesium,
    //   //     window.viewer,
    //   //     newCesiumVideo3d
    //   //   );
    //   // }
    // });
  },
  methods: {
    clockEntity(position) {
      this.longlat = position.longlat;
      this.Cartesian3 = position.Cartesian3;
    },
  },
};
</script>
<style lang="scss" scoped>
.spantext {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  opacity: 0.8;
  overflow: auto;
  color: #fff;
}
</style>
