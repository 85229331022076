export default `
uniform float mixNum;
uniform vec2 sizeSrc;
uniform vec2 sizeSplit;
uniform vec2 offsetSrc;
uniform sampler2D mask;

uniform sampler2D mapx;
uniform sampler2D mapy;
uniform sampler2D colorTexture;
uniform sampler2D stcshadow;
uniform sampler2D videoTexture;
uniform sampler2D depthTexture;
uniform mat4 _shadowMap_matrix;
uniform vec4 shadowMap_texelSizeDepthBiasAndNormalShadingSmooth;
varying vec2 v_textureCoordinates;

void main() {
  vec2 uv = v_textureCoordinates;
  gl_FragColor = texture2D(colorTexture, uv);
  vec4 currD = texture2D(depthTexture, uv);
  if (currD.r >= 1.0)
    return;

  float z_window = czm_unpackDepth(currD);
  z_window = czm_reverseLogDepth(z_window);
  float near = czm_depthRange.near;
  float far = czm_depthRange.far;
  float depth = (2.0 * z_window - near - far) / (far - near);
  vec4 cPos = czm_inverseProjection * vec4(uv.x * 2.0 - 1.0, uv.y * 2.0 - 1.0, depth, 1.0);
  vec4 sPos = _shadowMap_matrix * cPos / cPos.w;
  sPos /= sPos.w;
  if (any(lessThan(sPos.xyz, vec3(0.0))) || any(greaterThan(sPos.xyz, vec3(1.0))))
    return;

  float x_c = czm_unpackDepth(texture2D(mapx, sPos.xy));
  float y_c = czm_unpackDepth(texture2D(mapy, sPos.xy));
  float z_c = czm_unpackDepth(texture2D(mask, sPos.xy)) * mixNum;
  if (x_c < 0.00001 || y_c < 0.00001||z_c<0.0)
    return;

  float shadowR = texture2D(stcshadow, sPos.xy).r;
  if ((shadowR <= sPos.z * 0.9999) || (sPos.z - shadowR > 0.00001))
    return;

  float x_d = ( x_c * sizeSplit.x + offsetSrc.x ) / sizeSrc.x ;
  float y_d = ( y_c * sizeSplit.y + offsetSrc.y ) / sizeSrc.y ;

  vec4 video = texture2D(videoTexture, vec2(x_d, 1.0 - y_d));
  // float vMin = min(min(1.0 - sPos.st.t, sPos.st.t), min(1.0 - sPos.st.s, sPos.st.s));
  // float mixt = (vMin < 0.04) ? vMin * 25.0 : ((any(greaterThan(video.rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.25, 0.30)).rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.75, 0.70)).rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.25, 0.50)).rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.75, 0.50)).rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.50, 0.25)).rgb, vec3(0.001))) ||
  //   any(greaterThan(texture2D(videoTexture, vec2(0.50, 0.75)).rgb, vec3(0.001)))) ? 0.8 : 0.25);
  // float transparent = min(z_c, mixt);
  float transparent = z_c;
  gl_FragColor = mix(gl_FragColor, vec4(video.rgb, 1.0), transparent * video.a);
}
`
