<template lang="">
  <div class="regionForm">
    <el-form ref="form" :model="sizeForm" label-width="80px" size="mini">
      <el-form-item label="ID">
        <el-input v-model="sizeForm.regionId"></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="sizeForm.regionName"></el-input>
      </el-form-item>
      <el-form-item label="IP">
        <el-input v-model="sizeForm.modelHost"></el-input>
      </el-form-item>
      <el-form-item label="协议">
        <el-input v-model="sizeForm.modelProtocol"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="sizeForm.regionModelType" placeholder="请选择">
          <el-option
            v-for="item in RegionModelTypes"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <span>模型</span>
      <el-divider></el-divider>
      <el-form-item
        v-for="(mode, index) in sizeForm.models"
        :label="'模型' + (index + 1)"
        :key="mode.key"
      >
        <el-row>
          <el-col :span="4"
            ><el-input
              v-model="mode.modelName"
              placeholder="请输入名称"
            ></el-input
          ></el-col>
          <el-col :span="12"
            ><el-input
              v-model="mode.modelUri"
              placeholder="请输入地址"
            ></el-input
          ></el-col>
          <el-col :span="8">
            <el-button @click.prevent="removeDomain(mode)">删除</el-button>

            <el-switch v-model="mode.enabled"></el-switch>
            <el-switch v-model="mode.onFixedLayer"></el-switch>

            <el-button
              v-if="sizeForm.models.length - 1 == index"
              @click.prevent="addDomain(mode)"
              >+</el-button
            ></el-col
          >
        </el-row>
      </el-form-item>
      <el-form-item v-if="sizeForm.models.length == 0" label="模型">
        <el-button @click.prevent="addDomain()">+</el-button>
      </el-form-item>

      <span>地形</span>
      <el-divider></el-divider>

      <el-form-item
        v-for="(dem, index) in sizeForm.dems"
        :label="'地形' + (index + 1)"
        :key="dem.key"
      >
        <el-row>
          <el-col :span="16">
            <el-input
              v-model="sizeForm.dems[index].demUri"
              placeholder="请输入地址"
            ></el-input
          ></el-col>
          <el-switch v-model="dem.enabled"></el-switch>

          <!-- <el-col :span="8"
            ><el-button @click.prevent="removeDem(dem)">删除</el-button>
          </el-col> -->
        </el-row>
      </el-form-item>
      <!-- <el-form-item v-if="sizeForm.dems.length == 0" label="地形">
        <el-button @click.prevent="addDem()">+</el-button>
      </el-form-item> -->
      <!-- <el-form-item label="影像">
        <el-input v-model="sizeForm.regionId"></el-input>
      </el-form-item> -->
      <span>影像</span>
      <el-divider></el-divider>
      <el-form-item
        v-for="(tms, index) in sizeForm.tmses"
        :label="'影像' + (index + 1)"
        :key="tms.key"
      >
        <el-row>
          <el-col :span="4"
            ><el-input v-model="tms.tmsName" placeholder="请输入名称"></el-input
          ></el-col>
          <el-col :span="12"
            ><el-input v-model="tms.tmsUri" placeholder="请输入地址"></el-input
          ></el-col>
          <el-col :span="4">
            <!-- <el-input v-model="tms.imgType"></el-input> -->
            <el-select v-model="tms.imgType" placeholder="请选择">
              <el-option
                v-for="item in imgTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button @click.prevent="removeTms(tms)">删除</el-button>
            <el-switch v-model="tms.enabled"></el-switch>
            <el-button
              v-if="sizeForm.tmses.length - 1 == index"
              @click.prevent="addTms()"
              >+</el-button
            ></el-col
          >
        </el-row>
      </el-form-item>
      <el-form-item v-if="sizeForm.tmses.length == 0" label="影像">
        <el-button @click.prevent="addTms()">+</el-button>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="离地高度">
        <el-input-number
          v-model="sizeForm.heightOffset"
          :precision="2"
          :step="0.1"
          label="描述文字"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="使用">
        <el-switch v-model="sizeForm.enabled"></el-switch>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { imageType } from "./types.js";
import axios from "axios";

export default {
  props: {
    region: {
      type: Object,
      default: () => {
        return {
          regionId: "",
          regionName: "",
          modelHost: "",
          modelProtocol: "http",
          models: [
            {
              modelName: "",
              modelUri: "",
              enabled: true,
              onFixedLayer: true,
            },
          ],
          dems: [
            {
              demName: "地形",
              demUri: "",
              enabled: true,
            },
          ],
          tmses: [
            {
              tmsName: "",
              tmsUri: "",
              imgType: "",
              enabled: true,
            },
          ],
          heightOffset: 0,
          enabled: true,
          regionModelType: "Unknown",
          defaultEyeView: {
            longitude: 0,
            latitude: 0,
            height: 0,
            roll: 0,
            pitch: 0,
            yaw: 0,
          },
        };
      },
    },
  },

  mounted() {
    this.GetRegionModelTypes();
  },
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      sizeForm: this.region,
      imgTypes: imageType,
      RegionModelTypes: [],
      type: "",
    };
  },
  created() {},
  methods: {
    GetRegionModelTypes() {
      axios.get("/api/DataDictionaryTable/GetRegionModelTypes").then((row) => {
        console.log(row.data.data);
        this.RegionModelTypes = row.data.data;
      });
    },
    removeDomain(item) {
      var index = this.sizeForm.models.indexOf(item);
      if (index !== -1) {
        this.sizeForm.models.splice(index, 1);
      }
    },
    addDomain() {
      this.sizeForm.models.push({
        modelName: "",
        modelUri: "",
        enabled: true,
        onFixedLayer: true,
      });
    },
    removeDem(item) {
      var index = this.sizeForm.dems.indexOf(item);
      if (index !== -1) {
        this.sizeForm.dems.splice(index, 1);
      }
      switch (key) {
        case value:
          break;

        default:
          break;
      }
    },
    // addDem() {
    //   this.sizeForm.dems.push("");
    // },
    removeTms(item) {
      var index = this.sizeForm.tmses.indexOf(item);
      if (index !== -1) {
        this.sizeForm.tmses.splice(index, 1);
      }
    },
    addTms() {
      this.sizeForm.tmses.push({
        tmsName: "",
        tmsUri: "",
        imgType: "",
        enabled: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.regionForm {
  height: 500px;
  overflow: auto;
}
</style>
