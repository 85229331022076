<template>
  <div class="regionList">
    <div class="title">
      <div class="close" @click="close">❌</div>
    </div>

    <el-table
      :data="regionList"
      highlight-current-row
      stripe
      style="width: 100%"
      max-height="430"
      ref="singleTable"
      @row-dblclick="rowDbClick"
    >
      <el-table-column width="40" align="center">
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.isChecked"
            :indeterminate="scope.row.isIndeterminate"
            @change="handleSelectionChange(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column align="center" type="expand">
        <template slot-scope="props">
          <!-- 展开内容表 -->
          <el-table
            :data="props.row.models"
            style="width: 100%"
            class="child-table"
            row-key="guid"
            @row-dblclick="itemDBClick"
            :show-header="false"
          >
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'model')"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/model.png" alt="" srcset="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="modelName" label="模型名称" />
          </el-table>
          <el-table
            :data="props.row.dems"
            style="width: 100%"
            class="child-table"
            row-key="guid"
            @row-dblclick="itemDBClick"
            :show-header="false"
          >
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'dem')"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/dem.png" alt="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="demName" label="dem名称" />
          </el-table>
          <el-table
            :data="props.row.tmses"
            style="width: 100%"
            class="child-table"
            row-key="guid"
            @row-dblclick="itemDBClick"
            :show-header="false"
          >
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'tms')"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/tms.png" alt="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="tmsName" label="tms名称" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="#" type="index" width="50"> </el-table-column>
      <el-table-column prop="regionName" label="区域名称" width="200">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";
import { useModel } from "./hooks/model";
const { addModel, removeModel, removeModelAll } = useModel();
import { useTms } from "./hooks/tms";
const { addTms, removeTms, removeTmsAll } = useTms();
import { useDem } from "./hooks/dem";
const { addDem, removeDem } = useDem();
export default {
  components: {},
  data() {
    return {
      regionList: [],
      type: "Region",
    };
  },
  created() {
    this.$bus.on("uplist", () => {
      this.getcamera();
    });
  },
  beforeDestroy() {
    this.$bus.off("uplist");
  },
  mounted() {
    this.getcamera();
  },
  methods: {
    getcamera() {
      axios
        .post("/api/Setting/GetRegionModelList", {
          regionModelType: this.type,
          enabledOnly: true,
        })
        .then((row) => {
          let data = row.data.data;
          data.forEach((item) => {
            console.log(item, "item");
            item.isChecked = false;
            item.isIndeterminate = false;
            if (item.models && item.models.length > 0) {
              item.models.forEach((el) => {
                el.isChecked = false;
                el.type = "model";
              });
            }
            if (item.dems && item.dems.length > 0) {
              item.dems.forEach((el) => {
                el.isChecked = false;
                el.type = "dem";
              });
            }
            if (item.tmses && item.tmses.length > 0) {
              item.tmses.forEach((el) => {
                el.isChecked = false;
                el.type = "tms";
              });
            }
          });
          this.regionList = data;
        });
    },

    close() {
      this.$emit("close");
    },

    flyTo(modal) {
      window.viewer.flyTo(modal, { duration: 3.0, maximumHeight: 1000000 });
    },
    rowDbClick(row, column, event) {
      //父级双击跳转到模型区域
      console.log(row);
      if (row.models?.length > 0) {
        this.flyTo(row.models[0].model);
      }
      if (row.demList?.length > 0) {
        window.viewer.terrainProvider = row.demList[0];
      }
    },
    itemDBClick(row, column, event) {
      //子级双击跳转到模型区域
      console.log(row, column, event, "双击");
      switch (row.type) {
        case "model":
          if (row.model && row.model !== null && row.isChecked) {
            this.flyTo(row.model);
          } else {
            this.$notify.info({
              title: "视角移动未成功",
              message: "该模型还未加载",
            });
          }
          break;
        case "tms":
          if (row.tms && row.tms !== null && row.isChecked) {
            this.flyTo(row.tms);
          } else {
            this.$notify.info({
              title: "视角移动未成功",
              message: "该影像还未加载",
            });
          }
          break;
        case "dem":
          this.$notify.info({
            title: "视角移动未成功",
            message: "暂不支持视角移动到地形区域",
          });

        default:
          break;
      }
    },

    // 行选中事件
    handleSelectionChange(val) {
      //当父级选中状态被改变，所有子级状态改变
      val.models = val.models.map((i) => {
        i.isChecked = val.isChecked;
        return i;
      });
      val.dems = val.dems.map((i) => {
        i.isChecked = val.isChecked;
        return i;
      });
      val.tmses = val.tmses.map((i) => {
        i.isChecked = val.isChecked;
        return i;
      });
      //判断父级的选中状态，根据状态来加载或卸载模型，地形，影像
      if (val.isChecked) {
        this.regionLoading(val);
      } else {
        this.regionUnload(val);
      }

      val.isIndeterminate = false;
    },
    regionLoading(val) {
      for (let i = 0; i < val.models.length; i++) {
        const model = val.models[i];
        if (!model.model || model.model == null) {
          this.adModel(val, model);
        }
      }
      for (let i = 0; i < val.dems.length; i++) {
        const dem = val.dems[i];
        if (!dem.dem || dem.dem == null) {
          const url = `${val.modelProtocol}://${val.modelHost}/${dem.demUri}`;
          dem.dem = addDem(url);
        }
      }
      for (let i = 0; i < val.tmses.length; i++) {
        const tms = val.tmses[i];
        if (!tms.tms && tms.tms == null) {
          const url = `${val.modelProtocol}://${val.modelHost}/${tms.tmsUri}`;
          let t = addTms(url, tms.imgType);
          tms.tms = t;
        }
      }
    },
    regionUnload(val) {
      if (val?.models?.length > 0) {
        for (let i = 0; i < val.models.length; i++) {
          const model = val.models[i];
          if (model.model && model.model !== null) {
            const result = removeModel(model.model);
            console.log(result);
            model.model = null;
          }
        }
      }
      if (val?.dems?.length > 0) {
        for (let i = 0; i < val.dems.length; i++) {
          const dem = val.dems[i];
          if (dem.dem && dem.dem !== null) {
            const result = removeDem();
            console.log(result);
            dem.dem = null;
          }
        }
      }
      if (val?.tmses?.length > 0) {
        for (let i = 0; i < val.tmses.length; i++) {
          const tms = val.tmses[i];
          if (tms.tms && tms.tms !== null) {
            const result = removeTms(tms.tms);
            console.log(result, "行卸载tms");
            tms.tms = null;
          }
        }
      }

      //卸载该区域模型影像地形等
      console.log("卸载该区域模型影像地形等");
    },

    adModel(row, item) {
      //加载模型并判断模型是否加载成功
      const modelUrl = `${row.modelProtocol}://${row.modelHost}/${item.modelUri}`;
      const model = addModel(modelUrl, row.heightOffset);
      model.readyPromise.then(
        (tileset) => {
          // 模型加载成功后的处理逻辑
          console.log("模型加载成功！");
          item.model = model;
        },
        (error) => {
          // 加载失败的处理逻辑
          this.$notify.error({
            title: "加载" + item.modelName + "模型失败",
            message: "模型无法加载,err:" + error,
          });
        }
      );
    },

    // 每个小项选择事件-单选
    clickCheckItemFn(row, item, type) {
      console.log(row, item, type, "每小项勾选");
      if (item.isChecked) {
        //加载选中的模型，地形，影像
        //检查是否全部子级被选中改变父级选中状态
        switch (item.type) {
          case "model":
            this.adModel(row, item);
            break;
          case "dem":
            const demUri = `${row.modelProtocol}://${row.modelHost}/${item.demUri}`;
            const dem = addDem(demUri);
            item.dem = dem;
            break;
          case "tms":
            const tmsUri = `${row.modelProtocol}://${row.modelHost}/${item.tmsUri}`;
            const tms = addTms(tmsUri, item.imgType);
            console.log(tms, "单点加载tms");
            item.tms = tms;
            break;

          default:
            break;
        }
        this.allChecked(row);
      } else {
        //卸载取消勾选的模型,地形,影像
        // 检查是否所有数据取消勾选改变父级选中状态
        switch (item.type) {
          case "model":
            if (item.model && item.model != null) {
              const result = removeModel(item.model);
              item.model = null;
            }
            break;
          case "dem":
            if (item.dem && item.dem != null) {
              const result = removeDem();
              item.dem = null;
            }

            break;
          case "tms":
            if (item.tms && item.tms != null) {
              const result = removeTms(item.tms);
              console.log(result, "单点卸载tms");
              item.tms = null;
            }
            break;

          default:
            break;
        }

        this.noAllChecked(row);
      }
    },
    //子级勾选改变父级选中状态
    allChecked(row) {
      let modelIsAllChecked = row.models.every((v) => v.isChecked);
      let demIsAllChecked = row.dems.every((v) => v.isChecked);
      let tmseIsAllChecked = row.tmses.every((v) => v.isChecked);
      if (modelIsAllChecked && demIsAllChecked && tmseIsAllChecked) {
        row.isChecked = true;
        row.isIndeterminate = false;
      } else {
        row.isIndeterminate = true;
      }
    },
    //子级取消勾选，改变父级选中状态
    noAllChecked(row) {
      let modelIsAllChecked = row.models.every((v) => v.isChecked == false);
      let demIsAllChecked = row.dems.every((v) => v.isChecked == false);
      let tmseIsAllChecked = row.tmses.every((v) => v.isChecked == false);
      if (modelIsAllChecked && demIsAllChecked && tmseIsAllChecked) {
        row.isChecked = false;
        row.isIndeterminate = false;
      } else {
        row.isIndeterminate = true;
      }
    },
    //判断是否有选中的
    // isChecked(row){
    //   let modelIsAllChecked = row.models.filter((v) => v.isChecked).length==0?false:true;
    //   let demIsAllChecked = row.dems.filter((v) => v.isChecked).length==0?false:true;
    //   let tmseIsAllChecked = row.tmses.filter((v) => v.isChecked).length==0?false:true;
    // },

    // 单项操作-删除
    clickDelFn(row, index, item, itemIndex) {
      console.log(row, index, item, itemIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
.regionList {
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 100;

  color: #fff;
  height: 460px;
  background-color: #fff;
  .title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #000;
    // opacity: 0.8;
    color: #fff;
  }
  .button {
    width: 50px;
  }
  .add {
    text-align: center;
    line-height: 30px;
    width: 30px;
    text-shadow: 2px 2px 2px #022122;
    cursor: pointer;
    -webkit-animation: fontColor 1s;
    animation: fontColor 1s;
  }
}

::v-deep .el-table__header-wrapper .el-checkbox {
  visibility: hidden;
}

.el {
  width: 30px;
  height: 30px;
}

.item {
  margin-left: 3px;
  margin-right: 3px;
}
.img {
  height: 30px;
  width: 30px;
}
</style>
