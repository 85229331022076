/* eslint-disable no-unused-expressions */
import ECEF from "./CoordinateTranslate";
import FlvJs from "flv.js";
import videoShed3dShader from "./shader.js";
import axios from "axios";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { jessCreate, jessVideoKeepAlive } from "./utils/videoCreate.js";
const CesiumVideo3d = (function () {
  var Cesium = null;
  var videoShed3d = function (cesium, viewer, param) {
    Cesium = cesium;
    this.ECEF = new ECEF(); //坐标转换工具
    this.param = param;
    this.splitSrcWH = this.param.splitSrcWH;
    var option = this._initCameraParam();
    this.viewer = viewer;
    this.id = option.id;
    this.mapx = this.param.mapx;
    this.mapy = this.param.mapy;
    this.mask = this.param.mask;
    this.streamMediaCustomId = this.param.streamMediaCustomId;
    this.lastDecodedFrame = 0;
    this.statisticsIsOne = 0;
    this.staticFrameCounter = 0;

    this.optionType = {
      Color: 1,
      Image: 2,
      Video: 3,
    };

    option || (option = {});
    this.flvPlayer = null;
    this.Interval = null;
    this.tiaozheninterval = null;
    this.fixedCamera = null;

    this.channelId = option.channelId;
    this.scale = option.scale;
    this.near = option.near ? option.near : 0.1;

    this._cameraPosition = option.cameraPosition;
    this._position = option.position;
    this.type = option.type;
    this._alpha = option.alpha || 1;
    this.url = option.url;
    this.selCol = option.selCol;
    this.selRow = option.selRow;
    this.handle = option.streamHandle;
    this.color = option.color;
    this._debugFrustum = Cesium.defaultValue(option.debugFrustum, !0);
    this._aspectRatio = option.aspectRatio || this._getWinWidHei();
    this._camerafov =
      option.fov || Cesium.Math.toDegrees(this.viewer.scene.camera.frustum.fov);
    this.texture =
      option.texture ||
      new Cesium.Texture({
        context: this.viewer.scene.context,
        source: {
          width: 1,
          height: 1,
          arrayBufferView: new Uint8Array([255, 255, 255, 255]),
        },
        flipY: !1,
      });
    this.splitPixel = this.param.splitPixel;
    console.log(this.splitPixel);

    const canvas = document.createElement("canvas");

    this._videoPlay = Cesium.defaultValue(option.videoPlay, !0);
    this.defaultShow = Cesium.defaultValue(option.show, !0);
    if (!this.cameraPosition || !this.position) return;
    switch (this.type) {
      default:
      case this.optionType.Video:
        this.activeVideo(this.url, this.handle);
        break;
      case this.optionType.Image:
        this.activePicture(this.url);
        this.deActiveVideo();
        break;
      case this.optionType.Color:
        this.activeColor(this.color);
        this.deActiveVideo();
    }
    this._createShadowMap();
    this._getOrientation();
    this._addCameraFrustum();
    this._addPostProcess();
    this.viewer.scene.primitives.add(this);
    return this;
  };

  Object.defineProperties(videoShed3d.prototype, {
    alpha: {
      get: function () {
        return this._alpha;
      },
      set: function (e) {
        this._alpha = e;
      },
    },
    aspectRatio: {
      get: function () {
        return this._aspectRatio;
      },
      set: function (e) {
        this._aspectRatio = e;
        this._changeVideoWidHei();
      },
    },
    debugFrustum: {
      get: function () {
        return this._debugFrustum;
      },
      set: function (e) {
        this._debugFrustum = e;
        this.cameraFrustum.show = e;
      },
    },
    fov: {
      get: function () {
        return this._camerafov;
      },
      set: function (e) {
        this._camerafov = e;
        this._changeCameraFov();
      },
    },
    cameraPosition: {
      get: function () {
        return this._cameraPosition;
      },
      set: function (e) {
        e && ((this._cameraPosition = e), this._changeCameraPos());
      },
    },
    position: {
      get: function () {
        return this._position;
      },
      set: function (e) {
        e && ((this._position = e), this._changeViewPos());
      },
    },
    videoPlay: {
      get: function () {
        return this._videoPlay;
      },
      set: function (e) {
        this._videoPlay = Boolean(e);
        this._videoEle &&
          (this.videoPlay ? this._videoEle.paly() : this._videoEle.pause());
      },
    },
    params: {
      get: function () {
        var t = {};
        t.type = this.type;
        this.type = this.optionType.Color
          ? (t.color = this.color)
          : (t.url = this.url);
        t.position = this.position;
        t.cameraPosition = this.cameraPosition;
        t.fov = this.fov;
        t.aspectRatio = this.aspectRatio;
        t.alpha = this.alpha;
        t.debugFrustum = this.debugFrustum;
        return t;
      },
    },
    show: {
      get: function () {
        return this.defaultShow;
      },
      set: function (e) {
        this.defaultShow = Boolean(e);
        this._switchShow();
      },
    },
  });
  /**
   * 初始化视频投影参数（坐标系转换和计算等）
   * @returns
   */
  videoShed3d.prototype._initCameraParam = function () {
    var viewPoint = this.ECEF.enu_to_ecef(
      {
        longitude: this.param.position.x * 1,
        latitude: this.param.position.y * 1,
        altitude: this.param.position.z * 1 + this.param.elevation * 1,
      },
      {
        distance: this.param.far,
        azimuth: this.param.rotation.y * 1,
        elevation: this.param.rotation.x * 1,
      }
    );
    var position = Cesium.Cartesian3.fromDegrees(
      viewPoint.longitude,
      viewPoint.latitude,
      viewPoint.altitude
    );
    var cameraPosition = Cesium.Cartesian3.fromDegrees(
      this.param.position.x * 1,
      this.param.position.y * 1,
      this.param.position.z * 1 + this.param.elevation * 1
    );

    return {
      type: this.param.type,
      url: this.param.url,
      cameraPosition: cameraPosition,
      position: position,
      alpha: this.param.alpha,
      near: this.param.near,
      fov: this.param.fov,
      debugFrustum: this.param.debugFrustum,
      aspectRatio: this.param.aspectRatio,
      id: this.param.id,
      streamHandle: this.param.streamHandle,
      scale: this.param.scale,
      selRow: this.param.selRow,
      selCol: this.param.selCol,
      channelId: this.param.channelId,
    };
  };
  /**
   * 旋转
   */
  videoShed3d.prototype._changeRotation = function (e) {
    if (e) {
      this.param.rotation = e;
      var option = this._initCameraParam();
      this.position = option.position;
    }
  };
  /**
   * 相机位置
   */
  videoShed3d.prototype._changeCameraPosition = function (e) {
    if (e) {
      this.param.position = e;
      var option = this._initCameraParam();
      this.cameraPosition = option.cameraPosition;
    }
  };
  // 远平面
  videoShed3d.prototype._changeFar = function (e) {
    if (e) {
      this.param.far = e;
      var option = this._initCameraParam();
      this.position = option.position;
    }
  };
  // 近平面
  videoShed3d.prototype._changeNear = function (e) {
    if (e) {
      this.param.near = e;
      this.near = this.param.near;
      this._changeCameraPos();
    }
  };
  /** 获取三维地图容器像素大小
   */
  videoShed3d.prototype._getWinWidHei = function () {
    var viewer = this.viewer.scene;
    return viewer.canvas.clientWidth / viewer.canvas.clientHeight;
  };
  videoShed3d.prototype._changeCameraFov = function () {
    this.viewer.scene.postProcessStages.remove(this.postProcess);
    this.viewer.scene.primitives.remove(this.cameraFrustum);
    this._createShadowMap(this.cameraPosition, this.position);
    this._getOrientation();
    this._addCameraFrustum();
    this._addPostProcess();
  };
  videoShed3d.prototype._changeVideoWidHei = function () {
    this.viewer.scene.postProcessStages.remove(this.postProcess);
    this.viewer.scene.primitives.remove(this.cameraFrustum);
    this._createShadowMap(this.cameraPosition, this.position);
    this._getOrientation();
    this._addCameraFrustum();
    this._addPostProcess();
  };
  videoShed3d.prototype._changeCameraPos = function () {
    this.viewer.scene.postProcessStages.remove(this.postProcess);
    this.viewer.scene.primitives.remove(this.cameraFrustum);
    this.viewShadowMap.destroy();
    // this.cameraFrustum.destroy()
    this._createShadowMap(this.cameraPosition, this.position);
    this._getOrientation();
    this._addCameraFrustum();
    this._addPostProcess();
  };
  videoShed3d.prototype._changeViewPos = function () {
    this.viewer.scene.postProcessStages.remove(this.postProcess);
    this.viewer.scene.primitives.remove(this.cameraFrustum);
    this.viewShadowMap.destroy();
    // this.cameraFrustum.destroy()
    this._createShadowMap(this.cameraPosition, this.position);
    this._getOrientation();
    this._addCameraFrustum();
    this._addPostProcess();
  };
  videoShed3d.prototype._switchShow = function () {
    this.show = this.show
      ? !this.postProcess && this._addPostProcess()
      : (this.viewer.scene.postProcessStages.remove(this.postProcess),
        delete this.postProcess,
        (this.postProcess = null));
    this.cameraFrustum.show = this.show;
  };

  /** 创建视频Element
   * @param {String} url 视频地址
   **/
  // videoShed3d.prototype._createVideoEle = function (url) {
  //   this.videoId = "visualDomId";
  //   var a = document.createElement("VIDEO");
  //   this.tiaozheninterval = setInterval(() => {
  //     if (a.buffered.length) {
  //       let end = a.buffered.end(0); //获取当前buffered值
  //       let diff = end - a.currentTime; //获取buffered与currentTime的差值
  //       if (diff >= 3) {
  //         //如果差值大于等于3 手动跳帧 这里可根据自身需求来定
  //         a.currentTime = a.buffered.end(0); //手动跳帧
  //       }
  //     }
  //   }, 2000);
  //   a.setAttribute("autoplay", !0);
  //   this.flvPlayerOn(url, a);
  //   const Interval = setInterval(async () => {
  //     let res = await axios.post("/api/Media/RealPlayStatus", {
  //       Cameraid: this.id,
  //       ChannelId: this.channelId,
  //       streamHandle: this.handle,
  //       streamMediaCustomId: this.streamMediaCustomId,
  //     });
  //     if (res.data.code === 1) {
  //       this.setFireAndForgot(
  //         {
  //           Cameraid: this.id,
  //           ChannelId: this.channelId,
  //           streamHandle: this.handle,
  //         },
  //         res.data.data,
  //         2
  //       );
  //     } else {
  //       this.$message.error("保活断开");
  //       clearInterval(this.Interval);
  //     }
  //   }, 1000 * 15);
  //   this.Interval = Interval;

  //   this._videoEle = a;
  //   return a;
  // };
  // videoShed3d.prototype.flvPlayerOn = function (url, a) {
  //   // console.log("创建flv", url);

  //   let tmpPlayer = FlvJs.createPlayer({
  //     type: "flv",
  //     isLive: true,
  //     hasAudio: false,
  //     url: url,
  //   });
  //   tmpPlayer.on(FlvJs.Events.MEDIA_INFO, (res) => {
  //     // console.log("aaaaaaaaaaaaaaaaaaaaaaaa==>MEDIA_INFO", this.handle)
  //   });
  //   tmpPlayer.on(FlvJs.Events.METADATA_ARRIVED, (res) => {
  //     // console.log("bbbbbbbbbbbbbbbbbbbbbbbb==>METADATA_ARRIVED")
  //   });
  //   tmpPlayer.on(FlvJs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
  //     // console.log("cccccccccccccccccccccccc==>errorType:", this.handle, errorType, errorDetail, errorInfo);
  //   });
  //   tmpPlayer.on("statistics_info", (res) => {
  //     // console.log("res", res);
  //     // console.log("lastDecodedFrame", this.lastDecodedFrame, res.decodedFrames, this.handle);
  //     if (this.lastDecodedFrame == 0) {
  //       this.lastDecodedFrame = res.decodedFrames;
  //       return;
  //     }
  //     if (this.lastDecodedFrame != res.decodedFrames) {
  //       this.staticFrameCounter = 0;
  //       // console.log("不相等前", this.lastDecodedFrame, res.decodedFrames);
  //       this.lastDecodedFrame = res.decodedFrames;
  //       // console.log("不相等后", this.lastDecodedFrame, res.decodedFrames);
  //     } else {
  //       // console.log("dddddddddddddddddddddddd==>statistics_info", this.handle, "CFZ");
  //       this.staticFrameCounter++;
  //       if (this.staticFrameCounter > window.AppConfig.staticFrameThreshold) {
  //         // console.log("staticFrameThreshold", window.AppConfig.staticFrameThreshold);
  //         this.staticFrameCounter = 0;
  //         if (this.flvPlayer) {
  //           this.lastDecodedFrame = 0;
  //           // console.log("重新取流开始", new Date(), this.handle);
  //           this.flvPlayer.pause();
  //           this.flvPlayer.unload();
  //           this.flvPlayer.detachMediaElement();
  //           this.flvPlayer.destroy();
  //           this.flvPlayer = null;
  //           this.flvPlayerOn(url, a);
  //           // console.log("重新取流结束", new Date(), this.handle);
  //         }
  //       }
  //     }
  //   });
  //   tmpPlayer.attachMediaElement(a);
  //   tmpPlayer.load();
  //   // flvPlayer.play();
  //   this.flvPlayer = tmpPlayer;
  // };
  /** 视频投射
   * @param {String} url 视频地址
   */
  videoShed3d.prototype.activeVideo = function (url, handle) {
    // var video = this._createVideoEle(url, handle);
    let Jessibuca = jessCreate(url, () => {
      console.log("播放出现问题回调");
    });
    var video = Jessibuca.video;

    this.flvPlayer = Jessibuca.jess;
    this.Interval = jessVideoKeepAlive(
      this.id,
      this.channelId,
      this.handle,
      this.streamMediaCustomId
    );
    var that = this;
    if (video) {
      this.type = that.optionType.Video;
      var viewer = this.viewer;
      this.activeVideoListener ||
        (this.activeVideoListener = function () {
          that.videoTexture && that.videoTexture.destroy();
          that.videoTexture = new Cesium.Texture({
            context: viewer.scene.context,
            source: video,
            width: 1,
            height: 1,
            pixelFormat: Cesium.PixelFormat.RGBA,
            pixelDatatype: Cesium.PixelDatatype.UNSIGNED_BYTE,
          });
        });
      viewer.clock.onTick.addEventListener(this.activeVideoListener);
    }
  };
  videoShed3d.prototype.deActiveVideo = function () {
    if (this.activeVideoListener) {
      this.viewer.clock.onTick.removeEventListener(this.activeVideoListener);
      delete this.activeVideoListener;
    }
  };
  /** 图片投放
   * @param {String} url 图片地址
   **/
  videoShed3d.prototype.activePicture = function (url) {
    this.videoTexture = this.texture;
    var that = this;
    var img = new Image();
    img.onload = function () {
      that.type = that.optionType.Image;
      that.videoTexture = new Cesium.Texture({
        context: that.viewer.scene.context,
        source: img,
      });
    };
    img.onerror = function () {};
    img.src = url;
  };

  // videoShed3d.prototype.locate = function () {
  //   var cameraPosition = Cesium.clone(this.cameraPosition)
  //   var position = Cesium.clone(this.position)
  //   this.viewer.Camera.position = cameraPosition
  //   this.viewer.camera.direction = Cesium.Cartesian3.subtract(position, cameraPosition, new Cesium.Cartesian3(0, 0, 0))
  //   console.log(Cesium.Cartesian3.subtract(position, cameraPosition, new Cesium.Cartesian3(0, 0, 0)),position,cameraPosition,"分量差异")
  //   this.viewer.camera.up = Cesium.Cartesian3.normalize(cameraPosition, new Cesium.Cartesian3(0, 0, 0))
  // }
  videoShed3d.prototype.update = function (e) {
    this.viewShadowMap &&
      this.viewer.scene.frameState.shadowMaps.push(this.viewShadowMap); // *重点* 多投影
  };
  /**
   * 销毁投影
   */
  videoShed3d.prototype.destroy = function () {
    clearInterval(this.tiaozheninterval);
    clearInterval(this.Interval);
    axios
      .post("/api/Media/StopRealPlay", {
        streamHandle: this.handle,
        streamMediaCustomId: this.streamMediaCustomId,
      })
      .then((resp) => {
        if (this.param.streamurl && this.param.streamurl !== "") {
          this.setFireAndForgot(
            {
              streamHandle: this.handle,
              streamMediaCustomId: this.streamMediaCustomId,
              channelId: this.param.channelId,
            },
            resp,
            3
          );
        } else {
        }
      });

    // this.flvPlayer.pause()
    // this.flvPlayer.unload()
    // this.flvPlayer.detachMediaElement();
    this.flvPlayer.destroy().then(() => {});
    // this.flvPlayer=null
    this.viewer.scene.postProcessStages.remove(this.postProcess);
    this.viewer.scene.primitives.remove(this.cameraFrustum);
    // this._videoEle && this._videoEle.parentNode.removeChild(this._videoEle),
    this.activeVideoListener &&
      this.viewer.clock.onTick.removeEventListener(this.activeVideoListener);
    this.activeVideoListener && delete this.activeVideoListener;
    delete this.postProcess;
    delete this.viewShadowMap;
    delete this.color;
    delete this.viewDis;
    delete this.cameraPosition;
    delete this.position;
    delete this.alpha;
    delete this._camerafov;
    delete this._cameraPosition;
    delete this.videoTexture;
    delete this.cameraFrustum;
    delete this._videoEle;
    delete this._debugFrustum;
    delete this._position;
    delete this._aspectRatio;
    delete this.url;
    delete this.orientation;
    delete this.texture;
    delete this.videoId;
    delete this.type;
    delete this.viewer;
  };
  // 创建shadowmap
  videoShed3d.prototype._createShadowMap = function () {
    var e = this.cameraPosition;
    var t = this.position;
    var i = this.viewer.scene;
    var a = new Cesium.Camera(i);

    a.position = e;
    a.direction = Cesium.Cartesian3.subtract(
      t,
      e,
      new Cesium.Cartesian3(0, 0, 0)
    ); // 计算两个笛卡尔的组分差异。
    a.up = Cesium.Cartesian3.normalize(e, new Cesium.Cartesian3(0, 0, 0)); // 归一化
    var n = Cesium.Cartesian3.distance(t, e);
    this.viewDis = n;
    a.frustum = new Cesium.PerspectiveFrustum({
      fov: Cesium.Math.toRadians(this.fov),
      aspectRatio: this.aspectRatio,
      near: this.near,
      far: n,
    });
    a.twistLeft(Cesium.Math.toRadians(this.param.rotation.z));
    this.fixedCamera = a;
    // this.$bus.$emit("update")

    this.viewShadowMap = new Cesium.ShadowMap({
      lightCamera: a,
      enable: false,
      isPointLight: false,
      isSpotLight: true,
      cascadesEnabled: false,
      context: i.context,
      pointLightRadius: n,
      darkness: 1,
    });
  };
  // 获取shadowmap位置
  videoShed3d.prototype._getOrientation = function () {
    var e = this.cameraPosition;
    var t = this.position;
    var i = Cesium.Cartesian3.normalize(
      Cesium.Cartesian3.subtract(t, e, new Cesium.Cartesian3()),
      new Cesium.Cartesian3()
    );
    var a = Cesium.Cartesian3.normalize(e, new Cesium.Cartesian3());
    var n = new Cesium.Camera(this.viewer.scene);
    n.position = e;
    n.direction = i;
    n.up = a;
    i = n.directionWC;
    a = n.upWC;
    var r = n.rightWC;
    var o = new Cesium.Cartesian3();
    var l = new Cesium.Matrix3();
    var u = new Cesium.Quaternion();
    r = Cesium.Cartesian3.negate(r, o);
    var d = l;
    Cesium.Matrix3.setColumn(d, 0, r, d);
    Cesium.Matrix3.setColumn(d, 1, a, d);
    Cesium.Matrix3.setColumn(d, 2, i, d);
    var c = Cesium.Quaternion.fromRotationMatrix(d, u);
    this.orientation = c;
    return c;
  };

  // 创建视锥
  videoShed3d.prototype._addCameraFrustum = function () {
    var e = this;
    this.cameraFrustum = new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.FrustumOutlineGeometry({
          origin: e.cameraPosition,
          orientation: e.orientation,
          frustum: this.viewShadowMap._lightCamera.frustum,
          _drawNearPlane: !0,
        }),
        attributes: {
          // 视锥颜色
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(
            new Cesium.Color(0, 0.5, 0.5)
          ),
        },
      }),
      appearance: new Cesium.PerInstanceColorAppearance({
        translucent: !1,
        flat: !0,
      }),
      asynchronous: !1,
      show: this.debugFrustum && this.show,
    });
    this.viewer.scene.primitives.add(this.cameraFrustum);
  };
  /**
   * 后处理程序（将投影视频Texture，正畸Texture等传入着色器进行处理）
   */
  videoShed3d.prototype._addPostProcess = function () {
    var e = this;
    var t = videoShed3dShader;
    var i = e.viewShadowMap._isPointLight
      ? e.viewShadowMap._pointBias
      : e.viewShadowMap._primitiveBias;
    this.postProcess = new Cesium.PostProcessStage({
      fragmentShader: t,
      uniforms: {
        mixNum: function () {
          return e.alpha;
        },
        stcshadow: function () {
          return e.viewShadowMap._shadowMapTexture;
        },
        videoTexture: function () {
          return e.videoTexture;
        },
        _shadowMap_matrix: function () {
          return e.viewShadowMap._shadowMapMatrix;
        },
        shadowMap_lightPositionEC: function () {
          return e.viewShadowMap._lightPositionEC;
        },
        shadowMap_texelSizeDepthBiasAndNormalShadingSmooth: function () {
          var t = new Cesium.Cartesian2();
          t.x = 1 / e.viewShadowMap._textureSize.x;
          t.y = 1 / e.viewShadowMap._textureSize.y;
          return Cesium.Cartesian4.fromElements(
            t.x,
            t.y,
            i.depthBias,
            i.normalShadingSmooth,
            this.combinedUniforms1
          );
        },
        shadowMap_normalOffsetScaleDistanceMaxDistanceAndDarkness: function () {
          return Cesium.Cartesian4.fromElements(
            i.normalOffsetScale,
            e.viewShadowMap._distance,
            e.viewShadowMap.maximumDistance,
            e.viewShadowMap._darkness,
            this.combinedUniforms2
          );
        },
        vidcolor: function () {
          return e.texture1;
        },
        mapx: function () {
          return e.mapx;
        },

        mapy: function () {
          return e.mapy;
        },
        mask: function () {
          return e.mask;
        },

        offsetSrc: function () {
          var offset = new Cesium.Cartesian2();
          offset.x = e.splitPixel.left_top_x;
          offset.y = e.splitPixel.left_top_y;
          return offset;
        },
        sizeSrc: function () {
          var sizeSrc = new Cesium.Cartesian2();
          sizeSrc.x = e.splitSrcWH.Width;
          sizeSrc.y = e.splitSrcWH.Height;
          return sizeSrc;
        },
        sizeSplit: function () {
          var sizeSplit = new Cesium.Cartesian2();
          sizeSplit.x = e.splitPixel.right_bottom_x - e.splitPixel.left_top_x;
          sizeSplit.y = e.splitPixel.right_bottom_y - e.splitPixel.left_top_y;
          return sizeSplit;
        },
      },
    });
    this.viewer.scene.postProcessStages.add(this.postProcess);
  };

  videoShed3d.prototype.setFireAndForgot = function (req, resp, action) {
    if (window.AppConfig.isMediaNotifyiOn) {
      axios
        .post("/fire/v1.0/od/Api/MediaNotify", {
          req: req,
          resp: resp,
          action: action,
        })
        .then((res) => {
          if (!res.data.isSuccess) {
            this.$message.error(res.data.message);
          }
        });
    }
  };

  videoShed3d.prototype.isPowerOf2 = function (value) {
    return (value & (value - 1)) == 0;
  };
  return videoShed3d;
})();

export default CesiumVideo3d;
