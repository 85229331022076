import axios from "axios";
import { getimg } from "../utils/fusionUtils";
import CesiumVideo3d from "../CesiumVideo3d.js";
import * as Cesium from "cesium";
var maps = [];

class fusion {
  constructor(id, option) {
    this.id = id;
    this.option = option;
    this.newcesium = null;
    this.createVideo();
  }
  async createVideo() {
    console.log("开始投影");
    // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN); // 解除viewer的LEFT_DOWN事件监听器
    // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_DOWN事件监听器
    // if (newCesiumVideo3d !== null) {
    //   this.$message("已有视频投影，请先删除");
    //   return;
    // }
    if (this.option.position.x !== 0 && this.option.position.y !== 0) {
      const hr = Cesium.Math.toRadians(this.option.fov);
      const vr = Cesium.Math.toRadians(this.option.fovy);
      setTimeout(async () => {
        let mapx = null;
        let mapy = null;
        let mask = null;
        if (maps[this.id.id]) {
          mapx = maps[this.id.id].mapx;
          mapy = maps[this.id.id].mapy;
          mask = maps[this.id.id].mask;
        } else {
          await getimg(
            "/img/mapfile/" +
              window.AppConfig.mapfileSampleInterval +
              "/" +
              this.id.id +
              ".mapx.bmp"
          ).then((value) => {
            mapx = value;
          });

          await getimg(
            "/img/mapfile/" +
              window.AppConfig.mapfileSampleInterval +
              "/" +
              this.id.id +
              ".mapy.bmp"
          ).then((value) => {
            mapy = value;
          });
          await getimg("/img/mapfile/" + this.id.id + ".mask.bmp").then(
            (value) => {
              mask = value;
            }
          );
          maps[this.id.id] = {
            mapx: mapx,
            mapy: mapy,
            mask: mask,
          };
        }
        const res = await axios.post("/api/Media/StartRealPlay", {
          channelId: this.id.channelId,
          cameraId: this.id.id,
          rowScale: this.id.scale ? this.id.scale : 100,
          colScale: this.id.scale ? this.id.scale : 100,
          splitMode: this.id.splitMode ? this.id.splitMode : 0,
          mapH: this.id.splitRow ? this.id.splitRow : 1,
          mapW: this.id.splitCol ? this.id.splitCol : 1,
          selRow: this.id.selRow ? this.id.selRow : 1,
          selCol: this.id.selCol ? his.id.selCol : 1,
          leftTopX: this.id.splitPixel ? this.id.splitPixel.left_top_x : 0,
          leftTopY: this.id.splitPixel ? this.id.splitPixel.left_top_y : 0,
          rightBottomX: this.id.splitPixel
            ? this.id.splitPixel.right_bottom_x
            : 0,
          rightBottomY: this.id.splitPixel
            ? this.id.splitPixel.right_bottom_y
            : 0,
          streamMediaCustomId: this.id.streamMediaCustomId,
          StreamType: 0,
        });
        if (res && res.data.data) {
          // this.$message.success("获取流地址成功");
          console.log("获取流地址成功");
        } else {
          // this.$message.error("获取流地址失败");
          console.log("获取流地址失败");
          return;
        }
        //this.GetMatchView();
        this.option.url = res.data.data.stream_addr;
        this.option.channelId = this.id.channelId;
        this.option.streamHandle = res.data.data.stream_handle;
        this.option.leftTopX = this.id.splitPixel
          ? this.id.splitPixel.left_top_x
          : 0;
        this.option.leftTopY = this.id.splitPixel
          ? this.id.splitPixel.left_top_y
          : 0;
        this.option.rightBottomX = this.id.splitPixel
          ? this.id.splitPixel.right_bottom_x
          : 0;
        this.option.rightBottomY = this.id.splitPixel
          ? this.id.splitPixel.right_bottom_y
          : 0;
        this.option.splitPixel = this.id.splitPixel;
        let splitSrcWH = {
          Height: this.id.splitSrcHeight,
          Width: this.id.splitSrcWidth,
        };
        this.option.splitSrcWH = splitSrcWH;
        var objString = JSON.stringify(this.option);
        var op = JSON.parse(objString);
        op.aspectRatio = Math.tan(hr / 2) / Math.tan(vr / 2);
        // cesium中当纵视场角大于横视场角，会将横视场角赋给纵视场角且重新计算横视场角
        if (Math.tan(hr / 2) / Math.tan(vr / 2) <= 1) {
          op.fov = this.option.fovy;
        }
        op.mapx = mapx;
        op.mapy = mapy;
        op.mask = mask;
        op.streamMediaCustomId = this.id.streamMediaCustomId;
        op.streamurl = this.id.streamUrl;
        let val = new CesiumVideo3d(Cesium, window.viewer, op);
        // .then((val) => {
        //   newcesium = val;
        //   newCesiumVideo3d = newcesium;
        //   newcesiumvideo3d2.push(newcesium);
        //   this.getdata();
        // });
        this.newcesium = val;
        // newCesiumVideo3d = newcesium;
        // newcesiumvideo3d2.push(newcesium);
        // this.getdata();
      }, false);
    } else {
      this.$message("请在地图上选择点位");
    }
  }
  destroy() {
    this.newcesium.destroy();
  }
}
export { fusion };
