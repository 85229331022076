import * as Cesium from "cesium";
export function useTms(props) {
  function addTms(url, type) {
    console.log(url, type, "加载影像数据");
    let layers = window.viewer.imageryLayers;
    let tms = new Cesium.TileMapServiceImageryProvider({
      url: url,
      minimumLevel: 1,
      maximumLevel: 18,
      fileExtension: type,
    });
    let i = 0;
    tms.errorEvent.addEventListener((error) => {
      console.log(error, "影像数据加载失败");
      if (i == 0) {
        i += 1;
        console.log(error, "影像数据加载失败");
      }
    });
    let blackMarble = layers.addImageryProvider(tms);
    blackMarble.alpha = 1.0; // 0.0  全透明.  1.0 不透明.
    return blackMarble;
  }

  function removeTms(tms) {
    console.log("卸载影像数据");
    return window.viewer.imageryLayers.remove(tms, true);
  }
  function removeTmsAll() {
    return window.viewer.imageryLayers.removeAll(true);
  }

  return {
    addTms,
    removeTms,
    removeTmsAll,
  };
}
