import axios from "axios";
import { implant } from "./implant.js";
const { getUrlData } = implant();
import { useModel } from "../../components/regionList/hooks/model";
const { addModel, removeModel, removeModelAll } = useModel();
import { useTms } from "../../components/regionList/hooks/tms";
const { addTms, removeTms, removeTmsAll } = useTms();
import { useDem } from "../../components/regionList/hooks/dem";
const { addDem, removeDem } = useDem();
export function region() {
  async function getRegion(regionId) {
    let res = await axios
      .post("/api/Setting/GetRegionModelList", {
        regionModelIds: [regionId],
        regionModelType: "Unknown",
        enabledOnly: true,
      })
      .then((res) => {
        console.log(res);
        addRegion(res.data.data[0], regionId);
      });

    return res.data;
  }
  function addRegion(val, regionId) {
    console.log("regionId", regionId);

    for (let i = 0; i < val.models.length; i++) {
      const model = val.models[i];
      console.log("model", model);
      if (!model.model || model.model == null) {
        if (regionId) {
          if (!model.onFixedLayer) {
            continue;
          }
        }
        let m = adModel(val, model);
        if (val.models.length - 1 == i) {
          // window.viewer.flyTo(m).then(() => {});
        }
      }
    }
    for (let i = 0; i < val.dems.length; i++) {
      const dem = val.dems[i];
      if (!dem.dem || dem.dem == null) {
        const url = `${val.modelProtocol}://${val.modelHost}/${dem.demUri}`;
        dem.dem = addDem(url);
      }
    }
    for (let i = 0; i < val.tmses.length; i++) {
      const tms = val.tmses[i];
      if (!tms.tms && tms.tms == null) {
        const url = `${val.modelProtocol}://${val.modelHost}/${tms.tmsUri}`;
        let t = addTms(url, tms.imgType);
        tms.tms = t;
      }
    }
  }
  function adModel(row, item) {
    //加载模型并判断模型是否加载成功
    const modelUrl = `${row.modelProtocol}://${row.modelHost}/${item.modelUri}`;
    const model = addModel(modelUrl, row.heightOffset);
    model.readyPromise.then(
      (tileset) => {
        // 模型加载成功后的处理逻辑
        console.log("模型加载成功！");
        item.model = model;
      },
      (error) => {
        // 加载失败的处理逻辑
        this.$notify.error({
          title: "加载" + item.modelName + "模型失败",
          message: "模型无法加载,err:" + error,
        });
      }
    );
    return model;
  }
  return {
    getRegion,
  };
}
