<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="点位:">
        <el-switch
          v-model="entityPosition"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="视野锁定:">
        <el-switch
          v-model="viewLock"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="查询坐标:" style="margin-left: 100px">
        <div style="line-height: 20px">
          <el-radio v-model="formInline.coordinateSystem" :label="true"
            >经纬度</el-radio
          >
        </div>
        <div style="line-height: 20px">
          <el-radio v-model="formInline.coordinateSystem" :label="false"
            >笛卡尔</el-radio
          >
        </div>

        <!-- <el-switch
          v-model="formInline.coordinateSystem"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="经纬度"
          inactive-text="笛卡尔"
        >
        </el-switch> -->
      </el-form-item>

      <el-form-item label="">
        <el-input v-model="formInline.xyz" placeholder=""></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-form-item>
        <div class="spantextdiv">
          <span id="lonLat">{{ longlat }}</span>

          <el-button
            class="btn"
            type="primary"
            size="mini"
            data-clipboard-target="#lonLat"
            >复制经纬度</el-button
          >
        </div>
        <div class="spantextdiv">
          <span id="cartesian">{{ Cartesian3 }}</span
          ><el-button
            class="btn"
            type="primary"
            size="mini"
            data-clipboard-target="#cartesian"
            >复制笛卡尔</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as Cesium from "cesium";
import {
  inputposition1,
  inputposition2,
  keyboardControlClass,
} from "../../views/cesiumUtils/entitys";
var EntityCollection = null;
var addEntity = null;
export default {
  data() {
    return {
      formInline: {
        coordinateSystem: true,
        xyz: "",
      },
      entityPosition: false,
      handler: null,
      viewLock: false,
      entityId: 0,
      longlat: "0,0,0",
      Cartesian3: "0,0,0",
    };
  },
  mounted() {
    console.log("positionmounted");
    this.$nextTick(() => {
      EntityCollection = new Cesium.CustomDataSource("clickEntityCollection");
      window.viewer.dataSources.add(EntityCollection);
      addEntity = new Cesium.CustomDataSource("addEntity");
      window.viewer.dataSources.add(addEntity);
    });
  },
  watch: {
    entityPosition: {
      handler(val) {
        console.log(val);
        if (val) {
          this.addEvent();
        } else {
          this.removeEvent();
        }
      },
    },
    viewLock: {
      handler(val) {
        if (val) {
          this.lock();
        } else {
          this.removeLock();
        }
      },
    },
  },

  methods: {
    onSubmit() {
      if (this.formInline.coordinateSystem) {
        let ponstion = this.formInline.xyz.split(",");
        console.log(ponstion);
        let a = {};
        a.lng = ponstion[0];
        a.lat = ponstion[1];
        a.height = ponstion[2];
        inputposition1(Cesium, window.viewer, a, EntityCollection, "update");
      } else {
        let ponstion = this.formInline.xyz.split(",");
        console.log(ponstion);
        let a = {};
        a.lng = ponstion[0];
        a.lat = ponstion[1];
        a.height = ponstion[2];
        inputposition2(Cesium, window.viewer, a, EntityCollection, "update");
      }
    },
    addEvent() {
      this.handler = new Cesium.ScreenSpaceEventHandler(window.viewer.canvas);
      // todo：在椭球下点击创建点
      this.handler.setInputAction((event) => {
        console.log("event", event);
        var cartesian1 = window.viewer.scene.pickPosition(event.position);
        if (Cesium.defined(cartesian1)) {
          let cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
          var lng = Cesium.Math.toDegrees(cartographic1.longitude);
          var lat = Cesium.Math.toDegrees(cartographic1.latitude);
          var height = cartographic1.height; // 模型高度
          var mapPosition = {
            x: lng,
            y: lat,
            z: height,
          };
        }

        this.longlat =
          mapPosition.x + "," + mapPosition.y + "," + mapPosition.z;
        console.log(mapPosition, "mapPosition", this.longlat);
        this.Cartesian3 =
          cartesian1.x + "," + cartesian1.y + "," + cartesian1.z;
        let entity = addEntity.entities.add({
          id: "测试" + this.entityId,
          position: Cesium.Cartesian3.fromDegrees(
            mapPosition.x,
            mapPosition.y,
            mapPosition.z + 10
          ),
          point: {
            color: Cesium.Color.RED,
            pixelSize: 10,
            // disableDepthTestDistance: 5000
            // heightReference: Cesium.HeightReference.none,
          },
          label: {
            text:
              "经度：" +
              mapPosition.x +
              "\n" +
              "纬度：" +
              mapPosition.y +
              "\n" +
              "高度：" +
              mapPosition.z,
            // pixelSize: 5,
            font: "10px sans-serif",
            pixelOffset: new Cesium.Cartesian2(0, -30),
            // eyeOffset:Cesium.Cartesian3(100,100,100),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
          show: true,
        });

        window.viewer.flyTo(entity);
        this.entityId += 1;
        this.$emit("clockEntity", {
          longlat: this.longlat,
          Cartesian3: this.Cartesian3,
        });
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    removeEvent() {
      addEntity.entities.removeAll();
      this.handler.destroy();
      this.handler = null;
    },
    lock() {
      this.keyboardControl = new keyboardControlClass(Cesium, window.viewer);
      this.keyboardControl.enterKeyBoardMouseRoamingMode();
    },
    removeLock() {
      this.keyboardControl.exitKeyBoardMouseRoamingMode();
      this.keyboardControl = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-form-inline ::v-deep {
  .el-form-item__label {
    color: #fff !important;
  }
}
</style>
