function jessConfig(DIV) {
  return {
    container: DIV,
    decoder: "/jess/decoder-pro-simd.js",
    videoBuffer: 0.2, // 缓存时长
    isResize: false,
    loadingText: "加载中",
    debug: true,
    debugLevel: "debug",
    isMulti: true,
    useMSE: true,
    useSIMD: true,
    useWCS: true,
    autoWasm: false,
    hasAudio: false,
    useVideoRender: true,
    controlAutoHide: true,
    showBandwidth: false, // 显示网速
    showPerformance: false,
    operateBtns: {
      fullscreen: false,
      screenshot: false,
      play: false,
      audio: false,
    },
    autoWasm: false,

    loadingTimeout: 5,
    heartTimeout: 5,
    // 播放过程中，超时重连时，是否使用最后一帧来显示。
    heartTimeoutReplayUseLastFrameShow: true,
    loadingTimeoutReplay: -1,
    isFlv: true,
    isNotMute: false,
    supportDblclickFullscreen: false,
    demuxUseWorker: true,
    videoBufferDelay: 2,
  };
}
export { jessConfig };
