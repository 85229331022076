<template>
  <div class="regionList">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal="false"
    >
      <add
        ref="add"
        :region="region ? JSON.parse(JSON.stringify(region)) : undefined"
        v-if="dialogVisible"
      ></add>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
    <div class="searchBar">
      <div class="search">
        <el-select v-model="type" placeholder="请选择">
          <el-option
            v-for="item in RegionModelTypes"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div type="button" class="add" @click="addRegion">➕</div>
    </div>
    <el-table
      :data="regionList"
      highlight-current-row
      stripe
      style="width: 100%"
      max-height="400"
      ref="singleTable"
    >
      <el-table-column label="#" type="index"> </el-table-column>
      <el-table-column prop="regionName" label="区域名称"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <i
            class="el-icon-delete item"
            title="删除区域"
            @click.stop="delCamera(scope.$index, scope.row)"
          ></i>

          <i
            class="el-icon-edit item"
            title="修改区域"
            @click.stop="updateCameraConfig(scope.$index, scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";
import add from "./add.vue";
export default {
  components: {
    add,
  },
  data() {
    return {
      regionList: [],
      // regionLIstCopy: [],
      dialogVisible: false,
      region: undefined,
      title: "",
      type: "",
      RegionModelTypes: [],
    };
  },
  watch: {
    type() {
      this.getcamera();
    },
  },
  created() {
    this.$bus.on("uplist", () => {
      this.getcamera();
    });
  },
  beforeDestroy() {
    this.$bus.off("uplist");
  },
  mounted() {
    this.getcamera();
    this.GetRegionModelTypes();
  },
  methods: {
    GetRegionModelTypes() {
      axios.get("/api/DataDictionaryTable/GetRegionModelTypes").then((row) => {
        console.log(row.data.data);
        this.RegionModelTypes = row.data.data;
        this.type = "Unknown";
      });
    },
    getcamera() {
      axios
        .post("/api/Setting/GetRegionModelList", {
          regionModelType: this.type,
          enabledOnly: true,
        })
        .then((row) => {
          let data = row.data.data;

          this.regionList = data;
        });
    },

    async delCamera(index, row) {
      this.$confirm(`是否将${row.regionName}删除？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await axios.post("/api/Setting/DelRegionModelList", {
            regionModelIds: [row.regionId],
          });
          console.log(res);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: `删除成功`,
            });
          } else {
            this.$message({
              type: "info",
              message: "删除失败",
            });
          }
          this.getcamera();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    updateCameraConfig(index, row) {
      console.log(row);
      let region = row;
      // this.regionLIstCopy.find((el) => {
      //   return el.regionId == row.regionId;
      // });
      console.log(region, "要修改的区域");
      if (region) {
        this.region = region;
        this.title = "修改区域";
        this.dialogVisible = true;
      }
    },
    addRegion() {
      console.log("添加新区域");
      this.title = "新增区域";
      this.region = undefined;
      this.dialogVisible = true;
    },

    close() {
      this.$emit("close");
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.getcamera();
        })
        .catch((_) => {});
    },
    save() {
      let add = this.$refs.add.region;
      axios.post("/api/Setting/SetRegionModelList", [add]).then((row) => {
        this.dialogVisible = false;
        this.region = undefined;
        this.getcamera();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.regionList {
  // position: absolute;
  // bottom: 30px;
  // left: 10px;
  z-index: 100;

  color: #fff;
  height: 460px;
  background-color: #fff;
  .searchBar {
    display: flex;
    justify-content: space-between;
  }
  .title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #000;
    // opacity: 0.8;
    color: #fff;
  }
  .button {
    width: 50px;
  }
  .add {
    text-align: center;
    line-height: 30px;
    width: 30px;
    text-shadow: 2px 2px 2px #022122;
    cursor: pointer;
    -webkit-animation: fontColor 1s;
    animation: fontColor 1s;
  }
}

::v-deep .el-table__header-wrapper .el-checkbox {
  visibility: hidden;
}

.el {
  width: 30px;
  height: 30px;
}

.item {
  margin-left: 3px;
  margin-right: 3px;
}
.img {
  height: 30px;
  width: 30px;
}
</style>
