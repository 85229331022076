import * as Cesium from "cesium";
export function useModel(props) {
  function addModel(url, heightOffse) {
    let tileset = new Cesium.Cesium3DTileset({
      url,
      skipLevelOfDetail: true,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      skipLevels: 1,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      cullWithChildrenBounds: true,
      maximumScreenSpaceError: 16,
    });
    console.log(tileset, "模型");

    window.viewer.scene.primitives.add(tileset);

    tileset.readyPromise.then((tileset) => {
      // 高度偏差，向上是正数，向下是负数
      let heightOffset = heightOffse
        ? heightOffse
        : window.AppConfig.heightOffset;
      // 计算tileset的绑定范围
      let boundingSphere = tileset.boundingSphere;
      // 计算中心点位置
      let cartographic = Cesium.Cartographic.fromCartesian(
        boundingSphere.center
      );
      // 计算中心点位置的地表坐标
      let surface = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        0.0
      );
      // 偏移后的坐标
      let offset = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        heightOffset
      );
      let translation = Cesium.Cartesian3.subtract(
        offset,
        surface,
        new Cesium.Cartesian3()
      );
      // tileset.modelMatrix转换
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    });
    return tileset;
  }

  function removeModel(model) {
    console.log("卸载模型");
    return window.viewer.scene.primitives.remove(model);
  }
  function removeModelAll() {
    window.viewer.scene.primitives.removeAll();
  }

  return {
    addModel,
    removeModel,
    removeModelAll,
  };
}
