<template>
  <div>
    <!-- <div class="el">
      <i class="el-icon-s-unfold" @click="unfold" title="显示隐藏列表"></i>
    </div> -->
    <camera-split-area
      v-if="dialogVisible"
      @dialogNone="cameraSplitAreaShow"
      :dialogVisible="dialogVisible"
      :ID="ID"
      :cameraSplit="cameraSplit"
    ></camera-split-area>
    <cameraCfg
      :camera="currentCamera"
      v-if="showCameraConfig"
      @xyChange="xyChange"
      @updateCameraPosition="updateCameraPosition"
      @updateRotation="updateRotation"
      @updatenear="updatenear"
      @updatefar="updatefar"
      @updatefov="updatefov"
      @updatefovY="updatefovY"
      @updateAlpha="updateAlpha"
      @changeshow="changeshow"
      @createVideo="createVideo"
      @delVideo="delVideo"
      @getpoint2="getpoint2"
      @gotovideo="gotovideo"
      @save="save"
      @boundary="boundary"
    ></cameraCfg>
    <cameraconfig
      v-if="configShow"
      :cameraItem="cameraItem"
      @cancel="cancel"
      @uplist="uplist"
    ></cameraconfig>
    <div class="cameralist">
      <div>
        <div type="button" class="add" @click="addCamera">➕</div>
      </div>
      <el-table
        :data="cameralist"
        highlight-current-row
        @current-change="handleCurrentChange"
        stripe
        style="width: 100%"
        max-height="400"
        ref="singleTable"
        v-show="show"
      >
        <el-table-column label="#" type="index" width="50"> </el-table-column>
        <el-table-column prop="id" label="相机id" width="100">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i
              class="el-icon-video-camera item"
              title="设置为中心相机"
              @click.stop="SetTopoCentricCameraCfg(scope.$index, scope.row)"
            ></i>

            <i
              class="el-icon-menu item"
              title="刷新相机分割区域"
              @click.stop="CameraSplitArea(scope.$index, scope.row)"
            ></i>

            <i
              class="el-icon-delete item"
              title="删除相机"
              @click.stop="delCamera(scope.$index, scope.row)"
            ></i>

            <i
              class="el-icon-edit item"
              title="修改相机"
              @click.stop="updateCameraConfig(scope.$index, scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import cameraCfg from "./cameraCfg/index.vue";
import cameraconfig from "../cameraConfig/index.vue";
import cameraSplitArea from "../cameraSplitArea/index.vue";

export default {
  name: "cameralistTwo",
  props: {},
  components: {
    cameraCfg,
    cameraconfig,
    "camera-split-area": cameraSplitArea,
  },
  data() {
    return {
      cameralist: [],
      currentCamera: null,
      currentRow: null,
      show: true,
      showCameraConfig: false,
      configShow: false,
      cameraItem: null,
      dialogVisible: false,
      ID: null,
      cameraSplit: {
        cameraId: 0,
        imageOriginWidth: 0,
        imageOriginHeight: 0,
      },
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    this.getcamera();
  },
  methods: {
    getcamera() {
      axios.post("/api/Calib/GetCameras", {}).then((row) => {
        this.cameralist = row.data.data;
      });
    },

    handleCurrentChange(val) {
      this.currentRow = val;

      this.currentCamera = val;

      this.showCameraConfig = true;
      if (val) {
        // this.$emit("emitindex", val);
        this.$emit("emitindex", val);
      }
    },
    addCamera() {
      this.configShow = true;
      this.cameraItem = null;
    },
    cesiumCurrent(id) {
      let camera = this.cameralist.filter((item) => {
        return item.id === id;
      });
      this.$refs.singleTable.setCurrentRow(camera[0]);
    },
    unfold() {
      this.show = !this.show;
    },
    cesiumCurrent(id) {
      let camera = this.cameralist.filter((item) => {
        return item.id === id;
      });
      this.$refs.singleTable.setCurrentRow(camera[0]);
    },
    async SetTopoCentricCameraCfg(index, row) {
      console.log(index, row);
      this.$confirm(`是否将${row.id}设置为中心相机?`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let rew = await axios.post("/api/Setting/SetTopoCentricCameraCfg", {
            Point: row.point,
            Elevation: row.elevation,
            Lng: row.lng,
            Lat: row.lat,
            Height: row.height,
          });
          if ((rew.status = 200)) {
            this.$message({
              type: "success",
              message: `成功将${row.id}设置为中心相机`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    CameraSplitArea(index, row) {
      console.log(row);
      // this.$emit("CameraSplitArea", row);
      this.cameraId = row.channelId;
      this.ID = row.id;
      this.cameraSplit = {
        cameraId: row.id,
        imageOriginWidth: row.splitDstWidth,
        imageOriginHeight: row.splitDstHeight,
      };
      this.cameraSplitAreaShow(true);
    },
    async delCamera(index, row) {
      this.$confirm(`是否将${row.id}删除？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await axios.post("/api/Setting/DelCameraCfg", {
            keyPairsToDel: [
              {
                id: row.id,
                channelId: row.channelId,
              },
            ],
          });
          if (res.data.success) {
            this.$message({
              type: "success",
              message: `删除成功`,
            });
            this.getcamera();
          } else {
            this.$message({
              type: "info",
              message: "删除失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    updateCameraConfig(index, row) {
      // this.$emit("updateCameraConfig", row);
      this.configShow = true;
      this.cameraItem = row;
    },
    cancel() {
      this.configShow = false;
    },
    uplist() {
      this.getcamera();
    },

    cameraSplitAreaShow(show) {
      this.dialogVisible = show;
    },
    addCamera() {
      this.configShow = true;
      this.cameraItem = {};
    },

    xyChange(option) {
      console.log(option, "xyChange");

      this.$emit("xyChange", option);
    },
    updateCameraPosition(option) {
      this.$emit("updateCameraPosition", option);
    },
    updateRotation(option) {
      this.$emit("updateRotation", option);
    },
    updatenear(option) {
      this.$emit("updatenear", option);
    },
    updatefar(option) {
      this.$emit("updatefar", option);
    },
    updatefov(option) {
      this.$emit("updatefov", option);
    },
    updatefovY(option) {
      this.$emit("updatefovY", option);
    },
    updateAlpha(option) {
      this.$emit("updateAlpha", option);
    },
    changeshow(option) {
      this.$emit("changeshow", option);
    },
    createVideo(option) {
      this.$emit("createVideo", option);
    },
    delVideo(option) {
      this.$emit("delVideo", option);
    },
    getpoint2(option) {
      this.$emit("getpoint2", option);
    },
    gotovideo(option) {
      this.$emit("gotovideo", option);
    },
    save(option) {
      this.$emit("save", option);
    },
    boundary(option) {
      this.$emit("boundary", option);
    },
  },
};
</script>
<style lang="scss" scoped>
.cameralist {
  position: absolute;
  bottom: 50px;
  left: 10px;
  z-index: 100;

  padding: 10px;
  opacity: 0.8;
  color: #fff;
  height: 430px;
  background-color: #fff;

  .button {
    width: 50px;
  }
  .add {
    text-align: center;
    line-height: 30px;
    width: 30px;
    text-shadow: 2px 2px 2px #022122;
    cursor: pointer;
    -webkit-animation: fontColor 1s;
    animation: fontColor 1s;
  }
}

// .el-table__body tr:hover>td {
//     background-color: #134087 !important;
//  }
.el {
  width: 30px;
  height: 30px;
}

.item {
  margin-left: 3px;
  margin-right: 3px;
}
</style>
